import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {Payment} from '../../data/models/payment.model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {FormOfPayment} from "../../data/models/formOfPayment.model";
import {UserData} from "../../data/models/user-data.model";
import {IdentificationData} from "../../data/models/identification-data.model";
import {Person} from "../../data/models/person.model";
import {Address} from "../../data/models/address.model";

@Injectable({
  providedIn: 'root'
})
export class IdentificationService {

  constructor(
    private http: HttpClient,) { }


  checkUserAndGetMaskedExtendedData(data: IdentificationData): Observable<IdentificationData>{
    return this.http.post<IdentificationData>('/webabo/users/checkUserAndGetMaskedExtendedData', data).pipe(map( (identification: any) => {
      if (identification) {
        identification = this.buildObject(identification);
        return identification;
      } else {
        return null;
      }
    }));
  }

  checkUserAndValidateExtendedData(data: any): Observable<IdentificationData>{
    const extendedData = {};
    data.extendedData.forEach((val: string, key: string) => {
      extendedData[key] = val;
    });
    data.extendedData = extendedData;

    const maskedExtendedData = {};
    data.maskedExtendedData.forEach((val: string, key: string) => {
      maskedExtendedData[key] = val;
    });
    data.maskedExtendedData = maskedExtendedData;

    return this.http.post<IdentificationData>('/webabo/users/checkUserAndValidateExtendedData', data).pipe(map( (identification: any) => {
      identification = this.buildObject(identification);
      return identification;
    }));
  }

  createAccount(data: any): Observable<IdentificationData>{
    return this.http.post<IdentificationData>('/webabo/users/createAccount', data).pipe(map( (identification: any) => {
      identification = this.buildObject(identification);
      return identification;
    }));
  }

  private buildObject(identification: any) {
    identification.person = new Person(identification.person.title, identification.person.salutation, identification.person.firstname, identification.person.lastname, identification.person.initials, identification.person.middleName);
    identification.address = new Address(identification.address.street, identification.address.houseno, identification.address.zipcode, identification.address.city, identification.address.country, identification.address.webId, identification.address.backendId, identification.address.userBackendId, identification.address.userSSOId, identification.address.district, identification.address.longitude, identification.address.latitude);
    const maskedExtendedData = new Map<string, string>();
    if (identification.maskedExtendedData.EMAIL) {
      maskedExtendedData.set('EMAIL', identification.maskedExtendedData.EMAIL);
    }
    if (identification.maskedExtendedData.IBAN) {
      maskedExtendedData.set('IBAN', identification.maskedExtendedData.IBAN);
    }
    if (identification.maskedExtendedData.PHONE) {
      maskedExtendedData.set('PHONE', identification.maskedExtendedData.PHONE);
    }
    if (identification.maskedExtendedData.BIRTHDAY) {
      maskedExtendedData.set('BIRTHDAY', identification.maskedExtendedData.BIRTHDAY);
    }
    identification.maskedExtendedData = maskedExtendedData;
    return identification;
  }
}
