import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OfferComponent} from './webabo/offer/offer.component';
import {OfferDetailComponent} from './webabo/offer/offer-detail/offer-detail.component';
import {NotFoundComponent} from './core/components/not-found/not-found.component';
import {HupSubscriptionComponent} from './webabo/hup-subscription/hup-subscription.component';
import {MoveComponent} from './webabo/move/move.component';
import {ComplaintComponent} from './webabo/complaint/complaint.component';
import {
  HupSubscriptionDetailComponent
} from './webabo/hup-subscription/hup-subscription-detail/hup-subscription-detail.component';
import {LoginGuard} from './core/components/login/login.guard';
import {BaseDataResolverService} from './core/services/base-data-resolver.service';
import {ParentComponent} from './core/components/parent/parent.component';
import {ContactComponent} from './webabo/contact/contact.component';
import {SubChangeComponent} from './webabo/sub-change/sub-change.component';
import {ComplaintEditComponent} from './webabo/complaint/complaint-edit/complaint-edit.component';
import {SubChangeEditComponent} from './webabo/sub-change/sub-change-edit/sub-change-edit.component';
import {PaymentComponent} from './webabo/payment/payment.component';
import {PaymentEditComponent} from './webabo/payment/payment-edit/payment-edit.component';
import {
  SubscriptionPaymentEditComponent
} from './webabo/payment/subscription-payment-edit/subscription-payment-edit.component';
import {LandingPageComponent} from './webabo/landing-page/landing-page.component';
import {Globals} from './core/globals';
import {LandingPageGuard} from './webabo/landing-page/landing-page.guard';
import {ConfigResolverService} from './core/services/config-resolver.service';
import {ComplaintWihtoutLoginComponent} from './webabo/complaint/complaint-wihtout-login/complaint-wihtout-login.component';
import {OfferOrderedOverviewComponent} from './webabo/offer/offer-ordered-overview/offer-ordered-overview.component';
import {
  HupSubscriptionTerminateComponent
} from './webabo/hup-subscription/hup-subscription-terminate/hup-subscription-terminate.component';
import {RedirectComponent} from "./core/components/redirect/redirect.component";
import {LoginFormularComponent} from "./core/components/login-formular/login-formular.component";
import {PaymentRedirectComponent} from "./core/components/onlinepayment/payment-redirect/payment-redirect.component";
import {ContactWithoutLoginComponent} from "./webabo/contact/contact-without-login/contact-without-login.component";
import {OfferGroupComponent} from "./webabo/offer-group/offer-group.component";
import {ContactOverviewComponent} from "./webabo/contact/contact-overview/contact-overview.component";
import {ComplaintWithoutLoginAfterComponent}from './webabo/complaint/complaint-without-login-after/complaint-without-login-after.component';
import {IdentificationComponent} from "./webabo/identification/identification.component";

const defaultRoute = (Globals.USE_LANDING_PAGE) ? 'landing' : 'offer';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: defaultRoute,
    data: {config: ['removeOffersIfLoggedIn', 'removeLoginBox', 'removeNavigation']},
    pathMatch: 'full'
  },
  {
    path: 'landing',
    component: ParentComponent,
    canActivate: [LandingPageGuard],
    resolve: [ConfigResolverService],
    data: {config: ['removeOffersIfLoggedIn', 'removeLoginBox', 'removeNavigation']},
    children: [
      {path: '', component: LandingPageComponent}
    ]
  },
  {
    path: 'offer', component: ParentComponent, data: {breadcrumb: 'offer_group'}, children: [
      {path: '', component: OfferGroupComponent},
      {
        path: 'list/:offerGroup/:moreThanOneGroup',
        component: OfferComponent,
        resolve: [ConfigResolverService],
        data: {
          config: ['offer.highlight.offerId'],
          breadcrumb: 'offer'
        }
      },
      {
        path: ':offerId',
        component: OfferDetailComponent,
        resolve: [BaseDataResolverService, ConfigResolverService],
        data: {
          baseData: ['countrycodes', 'invoicemethods', 'paymentmethods', 'clienttypes', 'titles'],
          config: ['paymentprovider.paypal.paymentcode', 'paymentprovider.buckaroo.paymentcode', 'order.maxDelayToStart', 'order.price.combiPricesAsSum', 'paymentprovider.paypal.clientid', 'paymentprovider.paypal.isoCurrency'],
          breadcrumb: 'new_order'
        }
      },
      {
        path: 'ordered/:webId/:backendId/:otlc',
        component: OfferOrderedOverviewComponent,
        resolve: [BaseDataResolverService],
        data: {
          baseData: ['products', 'countrycodes', 'invoicemethods', 'paymentmethods', 'clienttypes', 'titles'],
          breadcrumb: 'new_order'
        }
      },
    ]
  },
  {
    path: 'subscription',
    component: ParentComponent,
    canActivate: [LoginGuard],
    resolve: [BaseDataResolverService, ConfigResolverService],
    data: {
      baseData: ['invoicemethods', 'paymentmethods', 'countrycodes', 'products', 'clienttypes', 'terminationtypes'],
      config: ['subscription_show_termination_button', 'showAdditionalSubscriptionInfos', 'faqButtonLink'],
      breadcrumb: 'subscription'
    },
    children: [
      {path: '', component: HupSubscriptionComponent},
      {path: ':webId/:backendId', component: HupSubscriptionDetailComponent}
    ]
  },
  {
    path: 'terminate-subscription/:subId',
    canActivate: [LoginGuard],
    component: ParentComponent,
    resolve: [BaseDataResolverService],
    data: {baseData: ['terminationtypes']},
    children: [
      {path: '', component: HupSubscriptionTerminateComponent}
    ]
  },
  {
    path: 'move',
    component: ParentComponent,
    canActivate: [LoginGuard],
    resolve: [BaseDataResolverService],
    data: {baseData: ['countrycodes', 'products', 'clienttypes', 'titles'], breadcrumb: 'move'},
    children: [
      {path: '', component: MoveComponent}
    ]
  },
  {
    path: 'complaint',
    component: ParentComponent,
    canActivate: [LoginGuard],
    resolve: [BaseDataResolverService, ConfigResolverService],
    data: {
      baseData: ['countrycodes', 'products', 'clienttypes', 'complaintgroupcodes', 'complaintactions'],
      config: ['complaint.reasongroup.default', 'complaint.reasongroup.defaultOnline', 'complaint.webFlags'],
      breadcrumb: 'complaint'
    },
    children: [
      {path: '', component: ComplaintComponent},
      {
        path: 'new/:subWebId/:subBackendId/:unauthorized/:changeable',
        component: ComplaintEditComponent,
        data: {breadcrumb: 'new_complaint'}
      },
      {
        path: 'edit/:compWebId/:compBackendId/:changeable/:isPrint',
        component: ComplaintEditComponent,
        data: {breadcrumb: 'edit_complaint'}
      },
    ]
  },
  {
    path: 'complaint-without-login',
    component: ParentComponent,
    resolve: [BaseDataResolverService, ConfigResolverService],
    data: {
      baseData: ['countrycodes', 'products', 'clienttypes', 'complaintgroupcodes', 'complaintactions'],
      config: ['complaint.reasongroup.default', 'complaint.reasongroup.defaultOnline', 'complaint.webFlags'],
      breadcrumb: 'complaint'
    },
    children: [
      {path: '', component: ComplaintWihtoutLoginComponent},
      {
        path: 'new/:subBackendId/:clientNo/:unauthorized/:changeable/:isPrint',
        component: ComplaintEditComponent,
        data: {breadcrumb: 'new_complaint'}
      },
      {
        path: 'complaint-without-login-after',
        component: ComplaintWithoutLoginAfterComponent,
        data: {breadcrumb: 'complaint_without_login_after'}
      },
    ]
  },
  {
    path: 'sub-change',
    component: ParentComponent,
    canActivate: [LoginGuard],
    resolve: [BaseDataResolverService],
    data: {
      baseData: ['countrycodes', 'products', 'clienttypes', 'intermissiontypes', 'titles'],
      config: ['subChange.donation.reason', 'subChange.epaper.reason', 'subChange.forward.reason', 'subChange.intermission.reason.default'],
      breadcrumb: 'subChange'
    },
    children: [
      {path: '', component: SubChangeComponent},
      {path: 'new/:subWebId/:subBackendId', component: SubChangeEditComponent, data: {breadcrumb: 'new_subChange'}},
      {
        path: 'edit/:changeWebId/:changeBackendId/:subBackendId/:changeable',
        component: SubChangeEditComponent,
        data: {breadcrumb: 'edit_sub-change'}
      },
    ]
  },
  {
    path: 'payment',
    component: ParentComponent,
    canActivate: [LoginGuard],
    resolve: [BaseDataResolverService],
    data: {baseData: ['products', 'invoicemethods', 'paymentmethods'], breadcrumb: 'payment'},
    children: [
      {path: '', component: PaymentComponent},
      {
        path: 'payment-edit/:paymentWebId/:paymentBackendId',
        component: PaymentEditComponent,
        data: {breadcrumb: 'edit_payment'}
      },
      {
        path: 'sub-payment-edit/:subWebId/:subBackendId',
        component: SubscriptionPaymentEditComponent,
        data: {breadcrumb: 'edit_payment'}
      }
    ]
  },
  {
    path: 'contact',
    component: ParentComponent,
    canActivate: [LoginGuard],
    resolve: [BaseDataResolverService],
    data: {baseData: ['contactrequesttypes'], breadcrumb: 'contact'},
    children: [
      {path: '', component: ContactComponent}
    ]
  },
  {
    path: 'contact-without-login',
    component: ParentComponent,
    resolve: [BaseDataResolverService],
    data: {
      baseData: ['contactrequesttypes', 'countrycodes', 'clienttypes', 'titles'],
      breadcrumb: 'contact'
    },
    children: [
      {path: '', component: ContactWithoutLoginComponent},
      {
        path: 'new/:subBackendId/:clientNo/:unauthorized/:isPrint',
        component: ContactComponent,
        data: {breadcrumb: 'contact'}
      },
    ]
  },
  {
    path: 'contact-overview',
    component: ParentComponent,
    data: {breadcrumb: 'contact'},
    children: [
      {path: '', component: ContactOverviewComponent}
    ]
  },
  {
    path: 'redirect',
    component: ParentComponent,
    data: {breadcrumb: 'redirect'},
    children: [
      {path: '', component: RedirectComponent}
    ]
  },
  {
    path: 'payment-redirect',
    component: ParentComponent,
    data: {breadcrumb: 'paymentRedirect'},
    children: [
      {path: '', component: PaymentRedirectComponent}
    ]
  },
  {
    path: 'login',
    component: ParentComponent,
    data: {breadcrumb: 'login'},
    children: [
      {path: '', component: LoginFormularComponent}
    ]
  },
  {
    path: 'identification',
    component: ParentComponent,
    resolve: [BaseDataResolverService, ConfigResolverService],
    data: {
      baseData: ['countrycodes'],
      config: ['identification.matchCount'],
      breadcrumb: 'identification'
    },
    children: [
      {path: '', component: IdentificationComponent}
    ]
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
